<template>
  <div id="bg">

    <div id="confirmOrder">
      <div class="confirmOrderBox">
        <h4 class="confirmOrderTitle">Details de souscription</h4>
        <div class="confirmOrderItems">
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Service commandé</b>
            <a class="col-sm-8 col-12">{{
              service.subscription_type + " " + service.subscription_label
            }}</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Modalité de paiement</b>
            <a class="col-sm-8 col-12">Annuelle</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Nombre d'année</b>
            <select id="nbYear" class="px-1 col-sm-3" v-model="nbYear" @change="calculatePrice()">
              <option value="1">1 année</option>
              <option value="2">2 années</option>
              <option value="3">3 années</option>
              <option value="4">4 années</option>
              <option value="5">5 années</option>
              <option value="6">6 années</option>
              <option value="7">7 années</option>
              <option value="8">8 années</option>
              <option value="9">9 années</option>
              <option value="10">10 années</option>
            </select>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Nom de Domaine</b>
            <a class="col-sm-8 col-12">{{ domain }}</a>
          </div>
          <!--  -->
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Prix de réservation HT</b>
            <a class="col-sm-8 col-12">{{ correctPrice(price_ht) }} FCFA</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">TVA ({{ service.tva_percent }}%)</b>
            <a class="col-sm-8 col-12">{{ correctPrice(service.price_per_year_tva) }} FCFA</a>
          </div>
          <div class="row confirmOrderItem">
            <b class="col-sm-4 col-12">Prix de réservation TTC</b>
            <a class="col-sm-8 col-12">{{ correctPrice(service.price_per_year_ttc) }} FCFA</a>
          </div>
        </div>
      </div>

      <div class="confirmOrderBox">
        <h4 class="confirmOrderTitle">Mode de paiement</h4>
        <div class="row">
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="1">
              <div class="col d-flex align-items-center">Paiement mobile</div>
              <div class="col-3 d-flex justify-content-center align-items-center">
                <i class="bi bi-phone fs-1"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="2">
              <div class="col d-flex align-items-center">Carte de credit</div>
              <div class="col-3 d-flex justify-content-center align-items-center">
                <i class="bi bi-credit-card-2-back fs-1"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="false">
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="3">
              <div class="col d-flex align-items-center">Paiement par Chèque</div>
              <div class="col-3 d-flex justify-content-center align-items-center">
                <i class="bi bi-card-heading fs-1"></i>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="col-sm-5 col-12 mb-3 paymod">
            <div class="row paymodbtn" ids="4">
              <div class="col d-flex align-items-center">Paiement par Virement</div>
              <div class="col-3 d-flex justify-content-center align-items-center">
                <i class="bi bi-bank fs-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="confirmOrderBox">
        <h4 class="confirmOrderTitle">Conditions de vente</h4>
        <p id="sellerContract">
          {{ service.contract }}
        </p>

        <input id="confirmOrderChecker" type="checkbox" v-model="confirmOrder" />
        <label for="confirmOrderChecker" class="ms-1"> Je suis d'accord</label>
      </div>

      <div id="cfoBtns" class="btn-group btn-group-toggle" data-toggle="buttons">
        <button v-on:click="back()" class="confirmOrderItemBackBtn btn btn-secondary">
          <i class="bi bi-arrow-left"></i>
        </button>
        <button v-on:click="validate()" class="confirmOrderItemValidateBtn btn btn-warning">
          Confirmer la commande <i class="bi bi-caret-right-fill"></i>
        </button>
      </div>

      <br />
      <br />
    </div>

  </div>
</template>

<script>
const $ = require("jquery");
import functions from "/src/common/js/functions";
import Globals from "/src/common/js/Globals";
import axios from "axios";
import waitLoader from "/src/common/js/waitLoader";
import popup from "/src/common/js/popup";
//
import localforage from "localforage";

let currentDate = new Date();
let annee = currentDate.getFullYear();
let mois = currentDate.getMonth() + 1;
let jour = currentDate.getDate();
let heure = currentDate.getHours();
let minute = currentDate.getMinutes();
let seconde = currentDate.getSeconds();
let transaction_id = "" + annee + mois + jour + heure + minute + seconde;

//confirmOrder component
export default {
  data() {
    return {
      subsExists: false,
      //
      data: {},
      user: {},
      service: {},
      offer: {},
      confirmOrder: false,
      payModeId: -1,
      payNumber: "",
      payModes: Array(),
      domain: "",
      //
      selectPayMethod: "",
      currency: "XOF",
      site_id: "5869953",
      channels: "CREDIT_CARD",
      transaction_id: transaction_id,
      zip_code: "3215",
      //
      nbYear: 1,
    };
  },

  mounted() {
    document.getElementById("confirmOrderChecker").checked = false;
    this.confirmOrder = false;
    //
    waitLoader.show();
    this.update();
    //
    let $this = this;
    $(".paymodbtn").click(function () {
      $this.selectPaymentMethod(this);
    });
  },

  methods: {
    update() {
      //get data from cache
      localforage.getItem('ecocloud/fullaccess').then(async (result) => {
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          return;
        }
        try {
          jsData = JSON.parse(jsData);
        }
        catch (e) {
          return;
        }
        //
        this.data = jsData;
        //
        //get all services in json
        const getSelectedService = async () => {
          this.domain = this.data.Domain;
          this.subsExists = this.data.subs_ids != null ? true : false;
          //
          let subIds = this.data.subs_ids;
          subIds = functions.correctQueryString(subIds);
          //
          try {
            let resp = await axios.get(Globals.website_url + "php/api.php?query=get-user-subscription-by-ids/" + subIds);
            this.service = resp.data;
            //all prices
            this.price_ht = this.service.price_per_year;
            //
            this.service.price_per_year_ttc =
              Math.ceil(this.service.price_per_year_ttc / 5) * 5;
            //
            //get user data in json
            let userId = this.data.user_id;
            userId = functions.correctQueryString(userId);
            //
            resp = await axios.get(
              Globals.website_url + "php/api.php?query=get-user-data-by-id/" + userId
            );
            this.user = resp.data;
          } catch (err) {
            // Handle Error Here
            console.error(err);
          }
        };
        getSelectedService();
        //
        waitLoader.hide();
        //
      }).catch((err) => {
        console.log(err);
      });
    },

    calculatePrice() {
      //all prices
      let price = this.service.price_per_year * this.nbYear;
      this.price_ht = price;
      this.service.price_per_year_tva = price * (this.service.tva_percent / 100);
      this.service.price_per_year_ttc = price * (1 + this.service.tva_percent / 100);
      this.service.price_per_year_ttc = Math.ceil(this.service.price_per_year_ttc / 5) * 5;
      this.service.final_price_ttc = this.service.price_per_year_ttc;
    },

    correctPrice(price) {
      return functions.correctPrice(price);
    },

    setConfirmOrder() {
      this.comfirmOrder = $("#chooseOfferModality")[0].selectedIndex;
    },

    back() {
      this.$router.back();
    },

    selectPaymentMethod(obj) {
      $(".paymodbtn").attr("selected", "false");

      setTimeout(() => {
        obj.setAttribute("selected", "true");
      }, 5);
      //
      this.selectPayMethod = obj.getAttribute("ids");
    },

    validate() {
      if (!this.confirmOrder) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez lire et accepter les conditions de vente avant de poursuivre."
        );
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //
      //
      waitLoader.show();
      this.pay();
    },

    pay() {
      axios.post(
        Globals.website_url + "php/api.php?query=check-if-subscription-domain-is-free/" + this.domain
      ).then(async (response) => {
        let h = response.data;
        //on error
        if (h != "1") {
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Ce nom de domaine n'est plus disponible!<br>Vous pouvez supprimer cet enregistrement."
          );
          popup.setButton("Ok");
          popup.show();
          //
          return;
        }
        //on free domain
        this.payMethod();
        //
      }).catch((e) => {
        console.log(e);
        waitLoader.hide();
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de vérification sur le nom de domaine.");
        popup.setButton("Ok");
        popup.show();
      });
    },

    payMethod() {
      //FB Pixel event
      window.fbq('trackCustom', 'ExternalPayDomainBf',
        {
          domain: this.data.Domain,
        }
      );
      //Google Analytics
      this.$gtag.event('ExternalPayDomainBf',
        {
          domain: this.data.Domain,
        }
      );
      //
      switch (this.selectPayMethod) {
        case "1":
          this.mobileMoney();
          break;
        case "2":
          this.cardValidate();
          break;
        default:
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Veuillez choisir un mode de payment.");
          popup.setButton("Ok");
          popup.show();
          waitLoader.hide();
      }
    },

    mobileMoney() {
      let returnUrl = Globals.website_url + "?uid=" + this.data.user_id + "#/enregistrer-mon-compte";

      // let ext = this.domain.substring(this.domain.lastIndexOf(".") + 1);
      //
      var params = {
        items: [
          {
            description: this.service.subscription_type + " " + this.service.subscription_label,
            amount: this.service.price_per_year_ttc,
            quantity: 1,
          },
        ],
        returnURL: returnUrl,
        service_code: "700",
        plugin_type: "std_data",
      };

      axios.post("https://gateway.upay-bf.com:3005/v1", params).then((res) => {
        //on success
        if (res.data.success) {
          //
          let rid = res.data.requestId;
          let uid = this.data.user_id;
          let oid = this.service.offer_id;
          let sid = this.service.id;
          //
          rid = functions.correctQueryString(rid + "");
          uid = functions.correctQueryString(uid + "");
          sid = functions.correctQueryString(sid + "");
          oid = functions.correctQueryString(oid + "");
          let spp = functions.correctQueryString("1");
          let npp = functions.correctQueryString("1");
          let tva_percent = functions.correctQueryString(this.service.tva_percent + "");
          //
          axios.post(
            Globals.website_url + "php/api.php?query=set-service-payment-data/" + rid + "/" + uid + "/" + oid + "/" + sid + "/" + spp + "/" + npp + "//" + tva_percent + "/" + amount + "/" + nbYear
          ).then(async (response) => {
            let h = response.data;
            //on error
            if (h != "1") {
              waitLoader.hide();
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Problème d'enregistrement des données.");
              popup.setButton("Ok");
              popup.show();
              //
              return;
            }
            waitLoader.hide();
            //on success
            //
            location.replace(res.data.response_code);
          }).catch(() => {
            waitLoader.hide();
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Problème de connexion!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
          });
        }
      }).catch((err) => {
        console.log(err);

        waitLoader.hide();
        // Handle error...
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de validation du paiement.");
        popup.setButton("Ok");
        popup.show();
      });
    },

    async cardValidate() {
      if (!this.confirmOrder) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Vous devez confirmer le versement.");
        popup.setButton("Ok");
        popup.show();
        return;
      }
      waitLoader.show();

      let user = this.user;

      var dbData = new FormData();
      dbData.append("user_id", this.data.user_id);
      dbData.append("subs_id", this.service.id);
      dbData.append("transaction_id", "ECODEV_" + this.transaction_id);
      dbData.append("currency", this.currency);
      dbData.append("amount", this.service.price_per_year_ttc);
      dbData.append("customer_name", user.fname.substring(user.fname.indexOf(" ") + 1));
      dbData.append("customer_surname", user.fname.substring(0, user.fname.indexOf(" ")));
      dbData.append("customer_id", user.id);
      dbData.append("customer_email", user.email);
      dbData.append("customer_phone_number", user.tel);
      dbData.append("customer_city", "Abidjan");
      dbData.append("customer_country", "CI");
      dbData.append("customer_state", "CI");
      dbData.append("customer_address", "Abidjan");
      dbData.append("customer_zip_code", "00225");
      dbData.append("nbYear", this.nbYear);

      dbData.append("description", this.domain);
      //
      dbData.append("tva", this.service.tva_percent);
      dbData.append("offer_id", this.service.offer_id);
      dbData.append("promo", '');
      try {
        const response = await fetch(
          Globals.website_url + "php/payment-api/cardSubscriptionInfoPay.php",
          {
            method: "POST",
            body: dbData,
          }
        );

        let data = await response.text();

        if (data != 1) {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème d'enregistrement des données.");
          popup.setButton("Ok");
          popup.show();
          waitLoader.hide();
          return false;
        }
      } catch (err) {
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez vérifier votre connexion à internet puis reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        waitLoader.hide();
        return;
      }

      var formData = new FormData();
      formData.append("transaction_id", "ECODEV_" + this.transaction_id);
      formData.append("currency", this.currency);
      //
      formData.append("notify_url", Globals.website_url + "?uid=" + this.data.user_id + "#/enregistrer-mon-compte");
      formData.append("return_url", Globals.cinet_return_url);
      formData.append("apikey", Globals.cinet_apikey);
      //
      formData.append("site_id", this.site_id);
      formData.append("amount", this.service.price_per_year_ttc);
      formData.append("channels", this.channels);
      formData.append("customer_name", user.fname.substring(user.fname.indexOf(" ") + 1));
      formData.append("customer_surname", user.fname.substring(0, user.fname.indexOf(" ")));
      // dbData.append("customer_id", user.data.id);
      formData.append("customer_email", user.email);
      formData.append("customer_phone_number", user.tel);
      formData.append("customer_city", "Abidjan");
      formData.append("customer_country", "CI");
      formData.append("customer_state", "CI");
      formData.append("customer_address", "Abidjan");
      formData.append("customer_zip_code", "00225");
      formData.append("description", this.domain);

      fetch("https://api-checkout.cinetpay.com/v2/payment/", {
        method: "POST",
        body: formData,
      }).then(async function (response) {
        const resp = await response.json();
        //
        let url = resp.data.payment_url;
        location = url;
      }).catch(function (error) {
        console.log(error);
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez vérifier votre connexion à internet puis reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
      });
    },
  },
};
</script>

<style scoped>
#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*-------------------------------
ConfirmOrder
-------------------------------*/
#confirmOrder {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.confirmOrderBox {
  width: 100%;
  margin: auto;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
  margin-bottom: 32px;
}

.confirmOrderTitle {
  color: orange !important;
}

.confirmOrderItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.confirmOrderItems {
  margin-bottom: 32px;
}

.confirmOrderItem:nth-child(odd) {
  background-color: white;
}

.confirmOrderItem a {
  color: black;
}

.confirmOrderItem p {
  overflow: auto;
  max-height: 128px;
}

#confirmOrderPayNumber {
  padding: 0 14px;
  height: 68px;
}

.confirmOrderItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

.confirmOrderItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

@media only screen and (max-width: 316px) {}

.paymod {
  background-color: transparent;
  margin: 0 8px;
}

.paymodbtn {
  display: flex;
  height: 80px;
  border: solid 1px #ccc;
  border-radius: 3px;
  background-color: white;
}

.paymodbtn:hover {
  background-color: orange;
  color: white;
}

.paymodbtn[selected="true"] {
  background-color: #f60;
  color: white;
}
</style>
